import React from "react";
import "./Syrve.css";

const Syrve = () => {
  return (
    <div id="SyrveSection">
      <div id="SyrveSectionInner">
        <h3 className="SectionNumber">
          სარესტორნო პროგრამა <span>[01]</span>
        </h3>
        <div id="Syrve">
          <h2>Syrve</h2>
          <p>
            არის პროგრამული გადაწყვეტილება, რომელიც განკუთვნილია რესტორნების,
            კაფეების და სხვა ნებისმიერი სახეობის კვების ობიექტებისთვის
            <span>70000+ კმაყოფილი მომხმარებელი მსოფლიოს მაშტაბით</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Syrve;
