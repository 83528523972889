import React from "react";
import "./ForGeorgia.css";

const ForGeorgia = () => {
  return (
    <div id="ForGeorgiaSection">
      <div id="ForGeorgia">
        <h3 className="SectionNumber">
          სარესტორნო პროგრამა <span>[01]</span>
        </h3>
        <div id="ForGeorgiaContent">
          <p>
            პროგრამული გადაწყვეტილება სრულად ლოკალიზირებულია ქართულ ენაზე <br /> და
            სხვა ნებისმიერი სახეობის კვების ობიექტებისთვის
          </p>
          <div id="contentHeader">
            <h2>
              <span>მორგებულია </span>
              საქართველოსთვის
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForGeorgia;
