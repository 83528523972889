import React from 'react'
import './banner.css'
import ComputerBanner from '../../assets/img/3D Computer stand.png'
import Sarestorno from '../../assets/img/header/01 sarestorno gradient.svg'
import biznesis from "../../assets/img/header/02 biznesis gradient 222-01.svg"
import sruli from "../../assets/img/header/03 sruli gradient.svg"
import automatizacia from "../../assets/img/header/04 avtomatizacia gradient.svg"

const Banner = () => {
  return (
    <div id='banner'>
        <h2>
          <img id='p_Restaurant' src={Sarestorno} />
          <br />
          <img className='bannerSVG' id='biznesis' src={biznesis} />
          <br />
          <span className="flexbox">
            <img className='bannerSVG' src={sruli} />

            <img  id='p_Automatization' className='bannerSVG' src={automatizacia} />
          </span>
        </h2>
        <img src={ComputerBanner} id='bannerImg' alt='computer banner' />

        <h3 id='Miniheader'>
            24 საათიანი უფასო ტექნიკური და ანალიტიკური მხარდამჭერით
        </h3>
    </div>
  )
}

export default Banner