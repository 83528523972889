import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import SlidingText from './components/SlidingText/SlidingText';
import CanvasAnimation from './components/Canvas/CanvasAnimation';
import Syrve from './components/Syrve/Syrve';
import ListOfPros from './components/ListOfPros/ListOfPros';
import Subscriptions from './components/Subscriptions/Subscriptions';
import Features from "./components/Features/Features"
import Footer from './components/Footer/Footer';
import ForGeorgia from './components/Georgia/ForGeorgia';

// 
// 
// 


// 

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
        <Header />

      <Banner />
      <SlidingText />
      <Syrve />
      <ListOfPros />
      <Features />
      <CanvasAnimation />
      <ForGeorgia />


    <footer className='App-Footer'>
      <Footer />
    </footer>

    </div>

    
  );
}

export default App;
