import React from 'react'
import LOGO from '../../assets/img/LOGO@1.5x.png'
import './header.css'
import { LuArrowUpRight } from "react-icons/lu";


const Header = () => {
  return (
    <div id='header'>
        <div id='listing'>
        <ul>
          <li>
            <a href="#SyrveSection"><span>[01]</span>სარესტორნო პროგრამა</a>
          </li>
          <li>
            <a href="#"><span>[02]</span>IT მომსახურეობა</a>
          </li>
          <li>
            <a href="#"><span>[03]</span>ვებ დეველოპმენტი (eCommerce)</a>
          </li>
          <li>
            <a href="#"><span>[04]</span>მარკეტინგი</a>
          </li>
          <li>
            <a href="#"><span>[05]</span>ბუღალტერია</a>
          </li>
        </ul>
        </div>
        <h1><img src={LOGO} alt='Logo'/></h1>
        <p>უფასო დემონსტრაცია <LuArrowUpRight id='upRight' />
        </p>

    </div>
  )
}

export default Header